import projImg1 from "../assets/img/stayinmtn.png";
import projImg2 from "../assets/img/melyjones.png";
import projImg3 from "../assets/img/ckecom.png";
import eddyfournier from "../assets/img/eddyfournier.png";
import luminance from "../assets/img/luminance.png";
import tcBramois from "../assets/img/tcBramois.png";
import olfactioncanine from "../assets/img/olfa.png";
import julesDuc from "../assets/img/julesDuc.png";

export const Gallery = () => {

    return (
    <div className="gallery_body">
        <div className="gallery">
            <figure className="card">
                <a href="https://www.stayinmtn.com/" target="_blank" rel="noreferrer">
                    <img
                        src={projImg1}
                        alt="stayinmtn"
                    />
                </a>
            </figure>
            <figure className="card">
                <a href="https://julesduc.ch/" target="_blank" rel="noreferrer">
                    <img
                        src={julesDuc}
                        alt="Jules Duc"
                    />
                </a>
            </figure>
            <figure className="card">
                <a href="https://www.melyjones.ch/" target="_blank" rel="noreferrer">
                    <img
                        src={projImg2}
                        alt="melyjones"
                    />
                </a>
            </figure>
            <figure className="card">
                <a href="https://www.ck-ecom.com/" target="_blank" rel="noreferrer">
                    <img
                        src={projImg3}
                        alt="ck-ecom"
                    />
                </a>
            </figure>
            <figure className="card">
                <a href="https://tcbramois.ch/" target="_blank" rel="noreferrer">
                    <img
                        src={tcBramois}
                        alt="tcBramois"
                    />
                </a>
            </figure>
            <figure className="card">
                <a href="https://www.eddyfournier.ch" target="_blank" rel="noreferrer">
                    <img
                        src={eddyfournier}
                        alt="eddyfournier"
                    />
                </a>
            </figure>
            <figure className="card">
                <a href="https://luminance-health.com/" target="_blank" rel="noreferrer">
                    <img
                        src={luminance}
                        alt="luminance-health"
                    />
                </a>
            </figure>
            <figure className="card">
                <a href="https://www.olfactioncanine.ch/" target="_blank" rel="noreferrer">
                    <img
                        src={olfactioncanine}
                        alt="olfactioncanine"
                    />
                </a>
            </figure>
        </div>
    </div>
    )
}