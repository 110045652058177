import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
      translation: {
        "navbar": {
          "home": "Home",
          "skills": "Skills",
          "projects": "Projects",
          "about": "About",
          "more": "More",
          "services":"My Services",
        },
        "welcome": "Welcome to my Portfolio",
        "greeting": "Hi I'm Mathieu, ",
        "description": "From creating custom websites to developing bespoke management tools, my work encompasses all aspects of web development including WordPress solutions. Let's harness the power of the web to elevate your business presence online.",
        "connect": "Let's connect",
        "skills": "Skills",
        "Technologies": "Technologies",
        "skillsDesc": "As a full-stack developer based in Valais (Switzerland), I offer a wide range of technical skills and experience that enable me to deliver robust and high-quality web solutions. Key skills include bespoke WordPress sites and custom development tailored to your needs.",
        "project": "Projects",
        "projDesc": "Below, you'll find a selection of my recent work as a full-stack developer from Valais. Each project showcases my expertise across various technologies and my ability to handle projects from WordPress web site creation to custom development.",
        "fiverr": "Contact me on Fiverr",
        "fiverrBtn": "Check out my Gigs",
        "aboutTitle": "About Me",
        "about": "I'm Mathieu, a 28-year-old web developer with a zest for life and a fervent love for all things active and adventurous. From conquering triathlons to gliding down snow-covered slopes, I also extend my passion to creating dynamic web solutions. Travel and technology are my gateways to understanding the world, offering bespoke web experiences to clients in Valais and beyond.",
        "servicesHeader": "My Services",
        "serviceDetails": "Service Details",
        "servicePrice" : "Service's price",
        "price": {
          "choose": "Choose from flexible pricing options designed to fit your budget and project requirements. Whether you're looking for a one-time project or ongoing support, I offer transparent pricing tailored to your needs:",
          "fixedT": "Fixed Price:",
          "monthT": "Monthly Retainer:",
          "fixed": "Ideal for one-time projects with well-defined requirements. Receive a detailed proposal outlining the scope, timeline, and total cost before we begin, ensuring clarity and predictability in pricing.",
          "month": "Perfect for ongoing projects requiring regular updates and maintenance. This model provides continuous support at a competitive monthly rate, helping you manage your budget while keeping your site in top condition.",
          "notSure": "Not sure which option suits you best? Contact me for a consultation, and I’ll help you determine the most cost-effective approach for your project. Ready to get started? Click below to request a personalized invoice or more details about my pricing",
        },
        "contactMe": "Contact me to discuss your project and ensure outstanding results with timely delivery and ongoing support. Your satisfaction is my priority!",
        "getInvoice": "Get an Invoice",
        "wordpressDevelopment": {
          "title": "WordPress Development",
          "description": "Leveraging a background in Business Information Technology and software engineering, I craft responsive, visually appealing WordPress sites. Whether you need a basic informational website or a sophisticated e-commerce platform, I'm here to help. My skills in HTML, CSS, JavaScript, and Divi Builder enable me to enhance your online presence with custom functionalities. Ready to elevate your website?",
          "desc2" : "Discover the full spectrum of my WordPress development services tailored to meet your unique needs. My expertise encompasses a range of capabilities from site conception to the final launch, ensuring a seamless and comprehensive development process. Here's what I offer:",
          "serviceDetailsHeader": "Service details:",
          "ready": "Ready to transform your digital presence ? Let's collaborate to create a website that not only meets but exceeds your expectations. Contact me today to get started !",
          "serviceDetailsList": {
            "customDesign": "Stand out with a website that is not only functional but also beautifully designed to reflect your brand's identity. Each design is created with a focus on usability, aesthetics, and responsiveness.",
            "eCommerceSolutions": "Launch a powerful online store with custom e-commerce functionalities designed to enhance user experience and increase conversions. From product displays to checkout optimization, I handle it all.",
            "seoOptimization": "Improve your site’s visibility with search engine optimization techniques that are built into the development process, helping you rank higher and attract more organic traffic.",
            "mobileOptimization": "With mobile-first design principles, I ensure your site looks great and functions flawlessly across all devices, enhancing user engagement and accessibility.",
            "performanceEnhancements": "Speed up your website with optimized code and the latest technologies to keep users engaged and reduce bounce rates.",
            "ongoingSupport": "Receive continued support and maintenance post-launch to ensure your website remains up-to-date with the latest web standards and technologies.",
            "customDesignT": "Custom Design:",
            "eCommerceSolutionsT": "E-commerce Solutions:",
            "seoOptimizationT": "SEO Optimization:",
            "mobileOptimizationT": "Mobile Optimization:",
            "performanceEnhancementsT": "Performance Enhancements:",
            "ongoingSupportT": "Ongoing Support and Maintenance:",
          }
        },
        "webDev": {
          "desc1": "As a Swiss full-stack developer, I'm specializing in web development. With a Bachelor's in Business Information Technology and two years of intensive software engineering experience, I excel in creating captivating websites and feature-rich mobile apps. My expertise spans HTML, CSS, JavaScript, React.js, Vue.js, ensuring seamless, responsive designs across all platforms.",
          "contact1": "Contact me to discuss your project and ensure outstanding results with timely delivery and ongoing support. Your satisfaction is my priority!",
          "desc2": "Unlock the potential of professional web development with my comprehensive suite of services. From initial design to final deployment, I offer a personalized approach to building your web presence. Here are the key services I provide:",
          "FullT": "Full-Stack Development:",
          "RespT": "Responsive Web Design:",
          "WebT": "Progressive Web Apps (PWAs):",
          "ApiT": "Custom API Integration:",
          "DynaT": "Dynamic User Interactions: ",
          "SEOT": "Search Engine Optimization (SEO):",
          "MaintT": "Ongoing Maintenance and Updates:",
          "Full": "Experience end-to-end solutions with my full-stack capabilities in both front-end and back-end development. I handle everything from user interface design to server and database management.",
          "Resp": "Ensure your website delivers optimal viewing experience across all devices. My designs are crafted to be fully responsive, automatically adjusting to various screen sizes and orientations.",
          "Web": "Elevate your user experience with a progressive web app that combines the best of web and mobile applications for enhanced performance and offline availability.",
          "Api": "Enhance your website’s functionality with custom API integrations that allow your site to seamlessly interact with other services and applications.",
          "Dyna": "Engage your audience with dynamic web elements and interactive features using advanced JavaScript, React.js, and Vue.js.",
          "SEO": "Boost your site’s visibility with SEO strategies that help improve your ranking on major search engines, driving more traffic to your site.",
          "Maint": "Keep your website at peak performance with continuous maintenance and regular updates, ensuring compatibility with evolving web technologies.",
          "question": "Whether you’re looking to create a brand-new website or revamp an existing one, I am here to help you achieve your goals with precision and innovation. Contact me today to start your web development journey!",
        }
      }
    },
  // en: {
  //   translation: {
  //     "navbar": {
  //       "home": "Home",
  //       "skills": "Skills",
  //       "projects": "Projects",
  //       "about": "About",
  //       "more": "More",
  //     },
  //     "welcome": "Welcome to my Portfolio",
  //     "greeting": "Hi I'm Mathieu, ",
  //     "description": "Passionate about the IT world, I am currently working as a Full Stack Developer. Curious and passionate about innovation, I am interested in any project that could improve my skills and my goals.",
  //     "connect": "Let's connect",
  //     "skills": "skills",
  //     "Technologies": "Technologies",
  //     "skillsDesc": "As a full-stack developer, I possess a wide range of technical skills and experience that enable me to create robust and high-quality solutions. Some of my key skills include:",
  //     "project": "Projects",
  //     "projDesc": "Below, you'll find a selection of some of my recent work as a full-stack developer. Each project showcases my skills and expertise in a range of technologies",
  //     "fiverr": "Contact me on Fiverr",
  //     "fiverrBtn": "Check out my Gigs",
  //     "aboutTitle": "About Me",
  //     "about": "I'm Mathieu, a 28-year-old enthusiast with a zest for life and a fervent love for all things active and adventurous. From conquering triathlons to gliding down snow-covered slopes, the thrill of sport courses through my veins. Beyond the thrill of physical pursuits, my passion extends to the cosmos. I'm captivated by the mysteries of space, finding wonder in the galaxies and constellations that paint the night sky. Travel is my gateway to understanding the world, and meeting new people from diverse walks of life is my favorite way to broaden my horizons."
  //   }
  // },
  fr: {
    translation: {
      "navbar": {
        "home": "Accueil",
        "skills": "Compétences",
        "projects": "Projets",
        "about": "À propos",
        "more": "Plus",
        "services":"Mes Services",
      },
      "welcome": "Bienvenue sur mon Portfolio",
      "greeting": "Salut, je suis Mathieu, ",
      "description": "De la création de sites internet au développement sur-mesure d'outils de gestion, je propose des solutions complètes incluant des sites WordPress personnalisés. Profitez d'une visibilité accrue sur internet pour présenter vos activités.",
      "connect": "Contact",
      "skills": "Compétences",
      "Technologies": "Technologies",
      "skillsDesc": "En tant que développeur full-stack basé en Valais, je possède un large éventail de compétences techniques pour créer des solutions web robustes et de haute qualité. Mes compétences clés incluent la création de sites WordPress sur mesure et le développement personnalisé.",
      "project": "Projets",
      "projDesc": "Ci-dessous, vous trouverez une sélection de certains de mes travaux récents en tant que développeur full-stack. Chaque projet démontre mon expertise dans diverses technologies et ma capacité à gérer des projets allant de la création de sites web WordPress au développement personnalisé.",
      "fiverr": "Contactez-moi sur Fiverr",
      "fiverrBtn": "Voir mes prestations",
      "aboutTitle": "À propos de moi",
      "about": "Je suis Mathieu, un développeur web de 28 ans avec un goût pour la vie et un amour fervent pour tout ce qui est actif et aventureux. Des triathlons à la descente de pentes enneigées, je prolonge ma passion en créant des solutions web dynamiques. Les voyages et la technologie sont mes moyens de comprendre le monde, offrant des expériences web personnalisées aux clients du Valais et au-delà.",
      "servicesHeader": "Mes Services",
      "serviceDetails": "Détails du Service",
      "servicePrice" : "Prix du service",
      "price": {
        "choose": "Choisissez parmi des options tarifaires flexibles conçues pour s'adapter à votre budget et aux exigences de votre projet. Que vous soyez à la recherche d'un projet ponctuel ou d'un soutien continu, je vous propose des tarifs transparents adaptés à vos besoins :",
        "fixedT": "Prix fixe :",
        "monthT": "Paiement mensuelle : ",
        "fixed": "Idéal pour les projets ponctuels aux exigences bien définies. Vous recevrez une proposition détaillée décrivant l'étendue, le calendrier et le coût total avant de commencer.",
        "month": "Parfait pour les projets en cours nécessitant des mises à jour et une maintenance régulières. Ce modèle fournit une assistance continue à un tarif mensuel compétitif, vous aidant à gérer votre budget tout en maintenant votre site en parfait état.",
        "notSure": "Vous ne savez pas quelle option vous convient le mieux ? Contactez-moi pour une consultation, et je vous aiderai à déterminer l'approche la plus rentable pour votre projet. Vous êtes prêt à commencer ? Cliquez ci-dessous pour demander un devis personnalisée ou plus de détails sur mes tarifs.",
      },
      "contactMe": "Contactez-moi pour discuter de votre projet et garantir des résultats exceptionnels avec une livraison rapide et un soutien continu. Votre satisfaction est ma priorité!",
      "getInvoice": "Obtenir un Devis",
      "wordpressDevelopment": {
        "title": "Développement WordPress",
        "description": "Grâce à bachelor en informatique de gestion et deux ans d'expérience en temps que développeur full-stack, je crée des sites WordPress réactifs et visuellement attrayants. Que vous ayez besoin d'un site informatif de base ou d'une plateforme  e-commerce sophistiquée, je suis là pour vous aider. Mes compétences en HTML, CSS, JavaScript et Divi Builder me permettent d'améliorer votre présence en ligne avec des fonctionnalités personnalisées.",
        "desc2" : "Découvrez la gamme complète de mes services de développement WordPress adaptés à vos besoins uniques. Mon expertise englobe une gamme de capacités allant de la conception du site au lancement final, garantissant un processus de développement complet et sans faille. Voici ce que je propose :",
        "serviceDetailsHeader": "Détails du service :",
        "ready": "Prêt à transformer votre présence numérique ? Collaborons pour créer un site web qui non seulement répondra à vos attentes mais les dépassera. Contactez-moi dès aujourd'hui pour commencer !",
        "serviceDetailsList": {
          "customDesign": "Distinguez-vous avec un site web qui est non seulement fonctionnel mais également magnifiquement conçu pour refléter l'identité de votre marque. Chaque conception est créée avec un accent sur l'utilisabilité, l'esthétique et la réactivité.",
          "eCommerceSolutions": "Lancez une boutique en ligne puissante avec des fonctionnalités de commerce électronique personnalisées conçues pour améliorer l'expérience utilisateur et augmenter les conversions. Du affichage des produits à l'optimisation du paiement, je m'occupe de tout.",
          "seoOptimization": "Améliorez la visibilité de votre site avec des techniques d'optimisation pour les moteurs de recherche intégrées au processus de développement, vous aidant à obtenir un meilleur classement et à attirer plus de trafic organique.",
          "mobileOptimization": "Avec des principes de conception mobile-first, je m'assure que votre site est superbe et fonctionne parfaitement sur tous les appareils, améliorant l'engagement des utilisateurs et l'accessibilité.",
          "performanceEnhancements": "Accélérez votre site web avec un code optimisé et les dernières technologies pour maintenir les utilisateurs engagés et réduire les taux de rebond.",
          "ongoingSupport": "Recevez un soutien et une maintenance continus après le lancement pour garantir que votre site web reste à jour avec les dernières normes et technologies web.",
          "customDesignT": "Conception personnalisée :",
          "eCommerceSolutionsT": "Solutions E-commerce:",
          "seoOptimizationT": "Optimisation SEO :",
          "mobileOptimizationT": "Optimisation mobile :",
          "performanceEnhancementsT": "Amélioration des performances :",
          "ongoingSupportT": "Soutien et maintenance continus :",
        }
      },
      "webDev": {
        "desc1": "En tant que développeur full-stack suisse, je suis spécialisé dans le développement web. Avec un bachelor en informatique de gestion et deux ans d'expérience en temps que développeur full-stack, j'excelle dans la création de sites Web captivants et d'applications mobiles riches en fonctionnalités. Mon expertise s'étend à HTML, CSS, JavaScript, React.js, Vue.js, garantissant des conceptions transparentes et réactives sur toutes les plateformes.",
        "contact1": "Contactez-moi pour discuter de votre projet et garantir des résultats exceptionnels avec une livraison dans les délais et un soutien continu. Votre satisfaction est ma priorité !",
        "desc2": "Libérez le potentiel d'un développement web professionnel grâce à ma gamme complète de services. De la conception initiale au déploiement final, j'offre une approche personnalisée pour construire votre présence sur le web. Voici les principaux services que je propose :",
        "FullT": "Développement Full-Stack :",
        "RespT": "Conception Web réactive :",
        "WebT": "Applications Web progressives (PWA) :",
        "ApiT": "Intégration API personnalisée :",
        "DynaT": "Interactions dynamiques avec les utilisateurs : ",
        "SEOT": "Optimisation des moteurs de recherche (SEO) : ",
        "MaintT": "Maintenance et mises à jour continues :",
        "Full": "Expérimentez des solutions de bout en bout grâce à mes capacités de développement. Je m'occupe de tout, de la conception de l'interface utilisateur à la gestion du serveur et de la base de données.",
        "Resp": "Assurez-vous que votre site Web offre une expérience de visualisation optimale sur tous les appareils. Mes designs sont conçus pour être entièrement réactifs, s'adaptant automatiquement aux différentes tailles et orientations d'écran.",
        "Web": "Améliorez votre expérience utilisateur avec une application web progressive qui combine le meilleur des applications web et mobiles pour des performances accrues et une disponibilité hors ligne...",
        "Api": "Améliorez la fonctionnalité de votre site web grâce à des intégrations API personnalisées qui permettent à votre site d'interagir de manière transparente avec d'autres services et applications.",
        "Dyna": "Engagez votre public avec des éléments web dynamiques et des fonctionnalités interactives à l'aide de JavaScript avancé, React.js et Vue.js.",
        "SEO": "Boostez la visibilité de votre site grâce à des stratégies de référencement qui aident à améliorer votre classement sur les principaux moteurs de recherche, ce qui permet d'augmenter le trafic vers votre site.",
        "Maint": "Maintenez les performances de votre site web au plus haut niveau grâce à une maintenance continue et à des mises à jour régulières, garantissant la compatibilité avec les technologies web en constante évolution.",
        "question": "Que vous cherchiez à créer un tout nouveau site web ou à réorganiser un site existant, je suis là pour vous aider à atteindre vos objectifs avec précision et innovation. Contactez-moi dès aujourd'hui pour commencer votre voyage de développement web !",
      }
    }
  }

  // fr: {
  //   translation: {
  //     "navbar": {
  //       "home": "Accueil",
  //       "skills": "Compétences",
  //       "projects": "Projets",
  //       "about": "À propos",
  //       "more": "Plus",
  //     },
  //     "welcome": "Bienvenue sur mon Portfolio",
  //     "greeting": "Salut je suis Mathieu, ",
  //     "description": "Passionné par le monde de l'informatique, je travaille actuellement en tant que développeur Full Stack. Curieux et passionné d'innovation, je m'intéresse à tout projet pouvant améliorer mes compétences et mes objectifs.",
  //     "connect": "Contact",
  //     "skills" : "Compétences",
  //     "Technologies" : "Technologies",
  //     "skillsDesc" : "En tant que développeur full-stack, je possède un large éventail de compétences techniques et d'expérience qui me permettent de créer des solutions robustes et de haute qualité. Voici quelques-unes de mes compétences clés :",
  //     "projet" : "Projets",
  //     "projDesc" : "Ci-dessous, vous trouverez une sélection de certains de mes travaux récents en tant que développeur full-stack. Chaque projet met en valeur mes compétences et mon expertise dans un éventail de technologies",
  //     "fiverr" : "Contactez-moi sur Fiverr",
  //     "fiverrBtn" : "Voir mes préstations",
  //     "aboutTitle": "A propos de moi",
  //     "about" : "Je suis Mathieu, un enthousiaste de 27 ans qui a le goût de la vie et un amour fervent pour tout ce qui est actif et aventureux. De la conquête des triathlons à la descente des pentes enneigées, le plaisir du sport coule dans mes veines. Au-delà de l'excitation des activités physiques, ma passion s'étend au cosmos. Je suis captivé par les mystères de l'espace, m'émerveillant des galaxies et des constellations qui peignent le ciel nocturne. Les voyages sont ma porte d'entrée pour comprendre le monde, et rencontrer de nouvelles personnes de divers horizons est ma façon préférée d'élargir mes horizons"
  //   }
  //}
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "fr", // default language
    fallbackLng: "fr",
    interpolation: {
      escapeValue: false,
    }
  });

export default i18n;
